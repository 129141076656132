@use 'src/styles/color-styles';
@use 'src/styles/spacing';
@use 'src/styles/typography';

/* ===============
   TEXT 
   =============== */
.u-text--black {
  color: color-styles.zonar-getColor(black);
}

.u-text--black-base {
  color: color-styles.zonar-getColor(black-base);
}

.u-text--gray {
  color: color-styles.zonar-getColor(gray);
}

.u-text--blue {
  color: color-styles.zonar-getColor(blue);
}

.u-text--semibold {
  font-weight: typography.zonar-getTextWeight('semibold');
}

.u-text--bold {
  font-weight: typography.zonar-getTextWeight('bold');
}

.u-text--large {
  font-size: typography.zonar-getTextSize('large');
}

.u-text--small {
  font-size: typography.zonar-getTextSize('small');
}

/* ===============
   MARGIN 
   =============== */

.u-margin__right--extra-tiny {
  @include spacing.zonar-margin(extra-tiny, right);
}

.u-margin__right--tiny {
  @include spacing.zonar-margin(tiny, right);
}

.u-margin__right--normal {
  @include spacing.zonar-margin(normal, right);
}

.u-margin__right--small {
  @include spacing.zonar-margin(small, right);
}

.u-margin__top--tiny {
  @include spacing.zonar-margin(tiny, top);
}

.u-margin__top--large {
  @include spacing.zonar-margin(large, top);
}

.u-margin__top--xlarge {
  @include spacing.zonar-margin(xlarge, top);
}

.u-margin__bottom--tiny {
  @include spacing.zonar-margin(tiny, bottom);
}

.u-margin__bottom--small {
  @include spacing.zonar-margin(small, bottom);
}

.u-margin__bottom--medium {
  @include spacing.zonar-margin(medium, bottom);
}

.u-margin__bottom--normal {
  @include spacing.zonar-margin(normal, bottom);
}

.u-margin__bottom--large {
  @include spacing.zonar-margin(large, bottom);
}

.u-margin__top--medium {
  @include spacing.zonar-margin(medium, top);
}
