@import url('https://fonts.googleapis.com/icon?family=Material+Icons+Outlined'); // needed for the sidenav.

html,
body {
  height: 100%;
}
body {
  margin: 0;
}

app-root:has(.app-container-mobile) ~ .cdk-overlay-container {
  .custom-schedule-dialog {
    width: 100vw;
    height: 100vh;

    .custom-schedule-dialog-wrapper {
      margin: 0 auto;
    }
  }
}

app-root:has(.app-container-mobile) {
  .custom-shedule-info span:before {
    content: '\a';
    white-space: pre;
  }
}

.severity-options-container {
  mat-radio-group {
    .mat-radio-label {
      white-space: normal;
    }
  }
}
