.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.items-center {
  align-items: center;
}

/* ===============
   TEXT 
   =============== */
.u-text--black {
  color: #292929;
}

.u-text--black-base {
  color: #000;
}

.u-text--gray {
  color: #5b6770;
}

.u-text--blue {
  color: #0075b4;
}

.u-text--semibold {
  font-weight: 600;
}

.u-text--bold {
  font-weight: 700;
}

.u-text--large {
  font-size: 24px;
}

.u-text--small {
  font-size: 14px;
}

/* ===============
   MARGIN 
   =============== */
.u-margin__right--extra-tiny {
  margin-right: 3px;
}

.u-margin__right--tiny {
  margin-right: 6px;
}

.u-margin__right--normal {
  margin-right: 18px;
}

.u-margin__right--small {
  margin-right: 12px;
}

.u-margin__top--tiny {
  margin-top: 6px;
}

.u-margin__top--large {
  margin-top: 36px;
}

.u-margin__top--xlarge {
  margin-top: 48px;
}

.u-margin__bottom--tiny {
  margin-bottom: 6px;
}

.u-margin__bottom--small {
  margin-bottom: 12px;
}

.u-margin__bottom--medium {
  margin-bottom: 24px;
}

.u-margin__bottom--normal {
  margin-bottom: 18px;
}

.u-margin__bottom--large {
  margin-bottom: 36px;
}

.u-margin__top--medium {
  margin-top: 24px;
}